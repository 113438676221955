import { React, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./portfolio.css";
import DocumentTitle from "../DocumentTitle";
import Btnarray from "./btnarrary.json";
import Portfolioarray from "./portfolio.json";

const Portfolio = () => {
  DocumentTitle("Portfolio - Code Knight");
  const [activeButtonIndex, setActiveButtonIndex] = useState(null);
  const [filterTab, setFilterTab] = useState("All");

  const btnarray = Btnarray;

  const portfolioarray = Portfolioarray;

  const handleToggle = (index) => {
    setActiveButtonIndex(index);
    setFilterTab(btnarray[index]);
  };

  return (
    <section>
      <div className="portfolio px-12 pt-36 pb-24 lg:pt-16 lg:pb-24 md:pt-36 md:pb-24 ">
        <div className="text-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-14 text-bgmain m-auto"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z"
            />
          </svg>

          <p className="text-4xl text-white pt-8">Enjoy Our Portfolio!</p>

          <p className="mt-12 text-xl text-gray-300 italic font-doris font-medium">
            You can combine any width, spacing, item style & height etc. for
            your portfolio grids
          </p>
        </div>
      </div>

      {/* ==============Portfolio Section================ */}

      <div className="p-12 text-center justify-center items-center bg-white">
        <div className="button-area p4">
          {btnarray.map((btn, index) => (
            <button
              key={index}
              className={`rounded-md px-4 py-1 text-gray-800 mx-1 mt-1 ${
                activeButtonIndex === index
                  ? "bg-gray-600 text-white"
                  : "bg-gray-100"
              }`}
              onClick={() => handleToggle(index)}
            >
              {btn}
            </button>
          ))}
        </div>
        <div className="portfolio-area p-8 grid lg:grid-cols-2 md:grid-cols-1 p-12">
          {portfolioarray.map((portCard, index) => {
            if (portCard.skey.includes(filterTab)) {
              const skeyItems = portCard.skey.slice(1).join("/");

              return (
                <div
                  className="cursor-pointer m-4 filter-card flex rounded-md border border-solid border-slate-200 grid grid-cols-1 bg-gray-100 text-gray-600 hover:text-white hover:bg-bgmain transition ease-in-out delay-200 transition-colors hover:transition-colors hover:transition-transform"
                  key={index}
                >
                  <a
                    href={"/portfolio/" + portCard.title}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="relative">
                      <div className="bg-cover overflow-hidden bg-cover bg-no-repeat">
                        <LazyLoadImage
                          src={require(`../../assets/${portCard.portimg}`)}
                          alt={portCard.title}
                          className="transition duration-300 ease-in-out transform hover:scale-125"
                        />
                      </div>

                      <div className="img-content p-6">
                        <p className="p-0.5">{portCard.title}</p>
                        <p className="p-0.5 text-sm italic">{skeyItems}</p>
                      </div>
                    </div>
                  </a>
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
