import React from "react";
import "./home.css";
import GreatCompany from "./greatCompany";
import ImgFlow from "./imgFlow";
import OurPortfolio from "./ourPortfolio";
import Passionate from "./passionate";
import MyFields from "./myFields";
// import DocumentTitle from "../DocumentTitle";
import { Helmet } from "react-helmet";

const Home = () => {
  const description =
    "Code Knight is the best software company having over 10 years of experience located in Los Angeles";
  // DocumentTitle("Home - Code Knight");
  return (
    <section id="intro">
      <Helmet>
        <title>Home - Code Knight</title>
        <meta name="description" content={description} />
      </Helmet>
      <div className="introContent px-8 pt-40 pb-16 lg:p-60 lg:pt-96 md:p-44 md:pt-80">
        <p className="wel-co">
          <span>Welcome to Code Knight</span>
        </p>
        <div className="wel-co-border"></div>
        <p className="we-strive">
          <span>your trusted partner in cutting-edge </span>{" "}
          <span className="red-you">software development</span>
        </p>
        <div className="wel-co-border"></div>
        <p className="special-start">
          <span>
            Located in the vibrant tech hub of Los Angeles, CA, our team of
            expert-level developers is dedicated to delivering top-tier
            solutions that exceed expectations.
          </span>
        </p>
        <p className="special-start">
          <span>
            Code Knight specializes in Web/Mobile & Blockchain development
          </span>
        </p>
        <div className="boc-spacing"></div>
        <a href="../contact" className="btn get-started-btn">
          <span>GET STARTED!</span>
        </a>
      </div>
      <GreatCompany />
      <ImgFlow />
      <OurPortfolio />
      <Passionate />
      <MyFields />
    </section>
  );
};

export default Home;
