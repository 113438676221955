import React from "react";

const ImgFlow = () => {
  return (
    <div className="imgflow">
      <div className="background-flow">
        <div className="flow-content">
          <p className="we-build">
            <span>Innovative and Creative</span>{" "}
            <span className="red-you">Solutions</span>
          </p>
          <p className="we-build-mind">
            <span>
              Innovation is at the heart of what we do. Our team is always
              exploring new technologies and methodologies to bring fresh,
              creative solutions to your projects. We strive to deliver products
              that not only meet but exceed your expectations.
            </span>
          </p>

          <div className="boc-spacing"></div>
          <a href="../contact" className="btn get-started-btn">
            <span>LET'S WORK TOGETHER</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ImgFlow;
