import React from "react";
import "./home.css";
import imagecontent1 from "../../assets/home-content-1.jpg";
import imagecontent2 from "../../assets/home-content-2.jpg";
import imagecontent3 from "../../assets/home-content-3.jpg";
import { BookmarkIcon } from "@heroicons/react/24/solid";

const Card = {
  item1: [
    imagecontent1,
    "Expert-Level Developers",
    "Our team comprises highly skilled developers with extensive industry experience. We stay ahead of the curve by adopting the latest technologies and best practices, ensuring our solutions are always at the forefront of innovation.",
  ],
  item2: [
    imagecontent2,
    "High Quality and Performance",
    "Quality and performance are at the core of our development process. We meticulously test and optimize our solutions to guarantee they run smoothly and efficiently, providing the best possible user experience.",
  ],
  item3: [
    imagecontent3,
    "Client-Centric Approach",
    "At Code Knight, we prioritize our clients' needs. We work closely with you to understand your goals and requirements, ensuring our solutions are perfectly aligned with your vision and objectives.",
  ],
};

const GreatCompany = () => {
  return (
    <div className="great-company bg-white justify-between items-center">
      <div className="great-bookmark">
        <div className="bookmark w-5 h-5 absolute top-5">
          <BookmarkIcon />
        </div>
      </div>
      <p className="great-company-title">
        Why <strong className="great-red"> Code Knight?</strong>
      </p>
      <p className="great-company-content">
        We are great at what we do and our Services simply rock
      </p>

      <div className="md:columns-3 sm:columns-1 xs:columns-1 card-content">
        {Object.keys(Card).map((key) => {
          const [img, title, content] = Card[key];
          return (
            <div key={key} className="card">
              <img
                src={img}
                alt={title}
                className="company-img rounded-full max-w-full h-auto"
              />
              <p className="img_title">{title}</p>
              <div className="content_border"></div>
              <p className="img_content">{content}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GreatCompany;
