import React, { useState } from "react";

const CarouselSlider = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === slides.length - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
  };

  return (
    <div className="relative mt-12">
      <div className="relative h-96 rounded-lg overflow-hidden">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`absolute top-0 transition-transform duration-500 ease-in-out transform ${
              index === currentSlide
                ? "opacity-100 translate-x-0"
                : "opacity-0 translate-x-full"
            }`}
          >
            <div className="slider-content bg-slate-100 p-2 rounded">
              <p className="slider-letters">{slide.contents}</p>
            </div>
            <div className="p-6">
              <img
                className="rounded-full slider-img w-20 h-20"
                src={slide.img}
                alt={slide.title}
              />
              <div className="px-4 py-2 float-left">
                <h3 className="text-zinc-700 text-base mt-2">{slide.title}</h3>
                <p className="text-zinc-500 italic text-sm mt-1">
                  {slide.subtitle}
                </p>
                <div className="slider-btn-wrap">
                  <button className="slider-btn" onClick={prevSlide}>
                    <svg
                      className="w-6 h-6 text-gray-900 fill-current"
                      viewBox="0 0 24 24"
                    >
                      <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z" />
                    </svg>
                  </button>

                  <button className="slider-btn" onClick={nextSlide}>
                    <svg
                      className="w-6 h-6 text-gray-900 fill-current"
                      viewBox="0 0 24 24"
                    >
                      <path d="M8.59 16.59L10 18l6-6-6-6-1.41 1.41L13.17 12l-4.58 4.59z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CarouselSlider;
