import React from "react";
import ErrorImg from "../assets/404.jpg";
import "../App.css";
import DocumentTitle from "../components/DocumentTitle";

const Error = () => {
  DocumentTitle("404 page");

  return (
    <div className="bg-gray-400">
      <img
        className="errorimg pt-16 md:pt-16 sm:pt-16 lg:pt-0"
        src={ErrorImg}
        alt="404 page"
      />
    </div>
  );
};
export default Error;
