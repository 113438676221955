import React from "react";

const ProgressBar = ({ bgcolor, progress, height }) => {
  const Parentdiv = {
    height: height,
    width: "100%",
    backgroundColor: "whitesmoke",
    borderRadius: 10,
    margin: 0,
  };

  const Childdiv = {
    height: "100%",
    width: `${progress}%`,
    backgroundColor: bgcolor,
    borderRadius: 10,
    textAlign: "right",
  };

  const progresstext = {
    fontWeight: 600,
  };

  return (
    <div style={Parentdiv}>
      <div className="progressbar-color" style={Childdiv}>
        <strong
          className="progress-percent"
          style={progresstext}
        >{`${progress}%`}</strong>
      </div>
    </div>
  );
};

export default ProgressBar;
