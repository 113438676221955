import { React, useState } from "react";
import DocumentTitle from "../DocumentTitle";
import BlogData from "./blog.json";

const Blog = () => {
  DocumentTitle("Blog-Code Knight");
  const data = BlogData;
  const [search, setSearch] = useState("");
  console.log(search);
  return (
    <section>
      <div className="single-portfolio-bg">
        <p className="text-5xl text-white text-gray-600  pt-40 pb-20">Blog</p>
      </div>
      <div className="blog-section grid lg:grid-cols-4 md:grid-cols-1 sm:grid-cols-1 bg-white text-gray-600 p-16">
        <div className="md:col-span-1 lg:col-span-3 sm:col-span-1">
          {data
            .filter((filter) => {
              return search.toLowerCase() === ""
                ? filter
                : filter.title.toLowerCase().includes(search);
            })
            .map((bdata, index) => {
              return (
                <div
                  className="p-4 transition duration-300 ease-in-out transform"
                  key={index}
                >
                  <div className="text-sm text-gray-400 flex">
                    <p className="flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                        />
                      </svg>
                      <p className="ml-2">{bdata.name} |</p>
                    </p>
                    <p className="flex ml-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                      <p className="ml-2">{bdata.date}</p>
                    </p>
                  </div>
                  <p className="text-xl text-gray-800 pt-1">{bdata.title}</p>
                  <p className="pt-4">{bdata.contents}</p>
                </div>
              );
            })}
        </div>
        <div className="p-16">
          <input
            className="px-4 py-1 border border-gray-300 focus:border-gray-300"
            type="text"
            name="name"
            onChange={(e) => setSearch(e.target.value)}
          />
          <p className="text-md pt-4 text-gray-600">RECENT POSTS :</p>
          <p className="text-sm pt-4 text-gray-600">A BEGINNER'S GUIDE ...</p>
          <p className="text-md pt-4 text-gray-600">RECENT COMMENTS :</p>
        </div>
      </div>
    </section>
  );
};

export default Blog;
