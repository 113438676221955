import React from "react";
import "./about.css";
import AboutCarousel from "../aboutCarousel";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import BackgroundIMG from "../../assets/man_tablet.jpg";
import ProgressBar from "../Home/ProgressBar";
import CleanDesignImg from "../../assets/table_imac.jpg";
import DocumentTitle from "../DocumentTitle";
const aboout = () => {
  DocumentTitle("About - Code Knight");
  return (
    <section>
      <div className="in-code-knight lg:pt-72 lg:p-24 lg:pb-36 md:pt-72 md:p-24 md:pb-36 pt-36 p-16">
        <div className="text-center">
          <p className="text-5xl text-white">
            YOU ARE IN <span className="text-cyan-500">CODE</span> KNIGHT
            <div className="h-px bg-gray-300 my-8 w-20  mx-auto"></div>
          </p>

          <p className="mt-3 text-xl text-gray-300 italic font-doris font-medium">
            Our Web and App development expertise span across a variety of
            technologies and industries.
          </p>
        </div>
      </div>
      <div className="bg-bgmain grid grid-cols-1 lg:grid-cols-4 md:grid-cols-3">
        <div className="p-12 md:col-span-2 lg:col-span-3">
          <p className="text-white text-xl ">
            LET'S WORK ON YOUR EXCITING NEW PROJECT TOGETHER!
          </p>
        </div>
        <div className="p-10">
          <button className="text-md px-8 hover:bg-slate-50 hover:text-zinc-900 hover:border-zinc-200">
            FIND OUT MORE
          </button>
        </div>
      </div>

      <div className="bg-white px-12 px-20 py-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
        <div className="text-gray-500 text-md p-4">
          <span className="float-left text-center text-teal-600">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-10 h-10"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z"
              />
            </svg>
          </span>
          <div className="ml-16">
            <p className="text-md">WEBSITE DEVELOPMENT</p>
            <p className="mt-2 text-sm">
              We create responsive, user-friendly websites that not only look
              great but also perform exceptionally. From e-commerce platforms to
              custom web applications, our solutions are designed to meet your
              specific requirements.
            </p>
          </div>
        </div>

        <div className="text-gray-500 text-md p-4">
          <span className="float-left text-center text-teal-600">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-10 h-10"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z"
              />
            </svg>
          </span>
          <div className="ml-16">
            <p className="text-md">MOBILE DEVELOPMENT</p>
            <p className="mt-2 text-sm">
              Our mobile app development services cover both iOS and Android
              platforms. We build intuitive and feature-rich apps that engage
              users and drive business growth.
            </p>
          </div>
        </div>

        <div className="text-gray-500 text-md p-4">
          <span className="float-left text-center text-teal-600">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-10 h-10"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 8.25v-1.5m0 1.5c-1.355 0-2.697.056-4.024.166C6.845 8.51 6 9.473 6 10.608v2.513m6-4.871c1.355 0 2.697.056 4.024.166C17.155 8.51 18 9.473 18 10.608v2.513M15 8.25v-1.5m-6 1.5v-1.5m12 9.75-1.5.75a3.354 3.354 0 0 1-3 0 3.354 3.354 0 0 0-3 0 3.354 3.354 0 0 1-3 0 3.354 3.354 0 0 0-3 0 3.354 3.354 0 0 1-3 0L3 16.5m15-3.379a48.474 48.474 0 0 0-6-.371c-2.032 0-4.034.126-6 .371m12 0c.39.049.777.102 1.163.16 1.07.16 1.837 1.094 1.837 2.175v5.169c0 .621-.504 1.125-1.125 1.125H4.125A1.125 1.125 0 0 1 3 20.625v-5.17c0-1.08.768-2.014 1.837-2.174A47.78 47.78 0 0 1 6 13.12M12.265 3.11a.375.375 0 1 1-.53 0L12 2.845l.265.265Zm-3 0a.375.375 0 1 1-.53 0L9 2.845l.265.265Zm6 0a.375.375 0 1 1-.53 0L15 2.845l.265.265Z"
              />
            </svg>
          </span>
          <div className="ml-16">
            <p className="text-md">BLOCKCHAIN DEVELOPMENT</p>
            <p className="mt-2 text-sm">
              Harness the power of blockchain technology with our bespoke
              development services. We create secure and scalable blockchain
              applications that enhance transparency and efficiency.
            </p>
          </div>
        </div>

        <div className="text-gray-500 text-md p-4">
          <span className="float-left text-center text-teal-600">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-10 h-10"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12a7.5 7.5 0 0 0 15 0m-15 0a7.5 7.5 0 1 1 15 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077 1.41-.513m14.095-5.13 1.41-.513M5.106 17.785l1.15-.964m11.49-9.642 1.149-.964M7.501 19.795l.75-1.3m7.5-12.99.75-1.3m-6.063 16.658.26-1.477m2.605-14.772.26-1.477m0 17.726-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205 12 12m6.894 5.785-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495"
              />
            </svg>
          </span>
          <div className="ml-16">
            <p className="text-md">CHROME EXTENSION DEVELOPMENT</p>
            <p className="mt-2 text-sm">
              Enhance your browser experience with our custom Chrome extensions.
              Whether you need an extension for productivity, security, or any
              other purpose, we have the expertise to deliver.
            </p>
          </div>
        </div>
      </div>
      <AboutCarousel />
      <div className="p-12 demo-wrap">
        <img src={BackgroundIMG} alt="" className="service-highlight" />
        <div className="service-content">
          <div className="text-center">
            <p className="text-white text-lg">Service Highlights</p>
          </div>
          <div className="h-px bg-gray-300 my-8 w-32 mx-auto"></div>
          <div className="about-progress grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-2 xs:grid-cols-1">
            <div className="p-12 text-center w-64 m-auto">
              <CircularProgressbar
                value={"87"}
                text={"87%"}
                strokeWidth={5}
                styles={buildStyles({
                  rotation: 0.4,
                  textSize: "24px",
                  pathTransitionDuration: 0.5,
                  pathColor: `rgba(160, 204, 59, ${87 / 100})`,
                  textColor: "#fff",
                  backgroundColor: "#a0cc3b",
                })}
              />
              <p className="px-4 mt-4 text-white text-center italic text-md progress-font">
                Website Development
              </p>
            </div>
            <div className="p-12 text-center w-64 m-auto">
              <CircularProgressbar
                value={"92"}
                text={"92%"}
                strokeWidth={5}
                styles={buildStyles({
                  rotation: 0.4,
                  textSize: "24px",
                  pathTransitionDuration: 0.5,
                  pathColor: `rgba(249, 197, 7, ${92 / 100})`,
                  textColor: "#fff",
                  backgroundColor: "#a0cc3b",
                })}
              />
              <p className="px-4 mt-4 text-white text-center italic text-md progress-font">
                Custom Development
              </p>
            </div>
            <div className="p-12 text-center w-64 m-auto">
              <CircularProgressbar
                value={"84"}
                text={"84%"}
                strokeWidth={5}
                styles={buildStyles({
                  rotation: 0.4,
                  strokeLinecap: "butt",
                  textSize: "24px",
                  pathTransitionDuration: 0.2,
                  pathColor: `rgba(232, 37, 92, ${84 / 100})`,
                  textColor: "#fff",
                  backgroundColor: "#a0cc3b",
                })}
              />
              <p className="px-4 mt-4 text-white text-center italic text-md progress-font">
                Web, Mobile Design
              </p>
            </div>
            <div className="p-12 text-center w-64 m-auto">
              <CircularProgressbar
                value={"78"}
                text={"78%"}
                strokeWidth={5}
                styles={buildStyles({
                  rotation: 0.4,
                  strokeLinecap: "butt",
                  textSize: "24px",
                  pathTransitionDuration: 0.2,
                  pathColor: `rgba(8, 173, 167, ${78 / 100})`,
                  textColor: "#fff",
                  backgroundColor: "#a0cc3b",
                })}
              />
              <p className="px-4 mt-4 text-white text-center italic text-md progress-font">
                Mobile Applications
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* ==================about company===================== */}

      <div className="about-company p-16 bg-white">
        <div className="grid lg:grid-cols-3">
          <div className="lg:col-span-2">
            <div className="about-devider">
              <p className="p-8 text-gray-700 text-lg">
                ABOUT THE <strong className="text-cyan-600">COMPANY</strong>
              </p>
            </div>
            <div className="aboutcompany-content p-4 grid lg:grid-cols-2 md:grid-cols-2">
              <p className="text-gray-700 p-4">
                Code Knight specializes in custom Web development, custom Mobile
                applications. We love and take pride in our work. Pixel
                perfection and stunning design are our highly valued goals in
                all our projects.
              </p>
              <p className="text-gray-700 p-4 ">
                Have an exciting project in mind? We sure want to hear about it.
                Don't hesitate to contact us with your thoughts and ideas, we
                would love to take part in achieving your dream project.
              </p>
            </div>
          </div>
          <div className="col-span-1">
            <div className="about-devider">
              <p className="p-8 text-gray-700 text-lg">
                OUR <strong className="text-cyan-600">SKILLS</strong>
              </p>
            </div>
            <div className="p-8">
              <div className="progressbar-wrap">
                <p className="progress-title">Web Development</p>
                <ProgressBar bgcolor="gray" progress="93" height={8} />
              </div>
              <div className="progressbar-wrap">
                <p className="progress-title">Mobile Applications</p>
                <ProgressBar bgcolor="gray" progress="96" height={8} />
              </div>
              <div className="progressbar-wrap">
                <p className="progress-title">DevOps</p>
                <ProgressBar bgcolor="gray" progress="88" height={8} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ===========Code Knight is Awesome============== */}

      <div className="grid lg:grid-cols-2 bg-gray-200">
        <div className="bg-gray-800">
          <div className="p-16 text-center">
            <p className="text-2xl text-white text-center">
              Code Knight is <span className="text-cyan-500">Awesome</span>
              <div className="h-px bg-gray-300 my-8 w-20  mx-auto"></div>
            </p>
            <p className="text-gray-200 text-center">
              The best of the best is combined in Code Knight. It features fine
              aesthetics, strong functional backend, plenty of customizable
              elements, a vast array of theme customization options and last but
              not least a 5 star support. Fortuna is extremely diverse and
              implements Visual Composer.
            </p>
            <button className="hover:bg-gray-200 hover:text-gray-800 m-auto mt-8">
              <span className="float-left">BUY NOW </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="size-6 right-arrow pl-2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m8.25 4.5 7.5 7.5-7.5 7.5"
                />
              </svg>
            </button>
          </div>
        </div>
        <div>
          <div className="peo-img"></div>
        </div>
      </div>

      {/* ===================Clients a Year===================== */}

      <div className="grid lg:grid-cols-6 md:grid-cols-6 grid-cols-1 bg-gray-300">
        <div className="lg:col-span-4 md:col-span-4 flex p-12 grid md:grid-cols-4 lg:grid-cols-4 text-center justify-center items-center">
          <div className="p-4 text-center justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-12 text-teal-600 m-auto"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
              />
            </svg>
            <p className="text-gray-800 text-2xl pt-1">1200</p>
            <p className="text-gray-800 text-sm pt-1">Clients a Year</p>
          </div>

          <div className="p-4 text-center justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-12 text-teal-600 m-auto"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.5 18.75h-9m9 0a3 3 0 0 1 3 3h-15a3 3 0 0 1 3-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 0 1-.982-3.172M9.497 14.25a7.454 7.454 0 0 0 .981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 0 0 7.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 0 0 2.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 0 1 2.916.52 6.003 6.003 0 0 1-5.395 4.972m0 0a6.726 6.726 0 0 1-2.749 1.35m0 0a6.772 6.772 0 0 1-3.044 0"
              />
            </svg>

            <p className="text-gray-800 text-2xl pt-1">83</p>
            <p className="text-gray-800 text-sm pt-1">Online Shops</p>
          </div>

          <div className="p-4 text-center justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-12 text-teal-600 m-auto"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
              />
            </svg>

            <p className="text-gray-800 text-2xl pt-1">37</p>
            <p className="text-gray-800 text-sm pt-1">Plugins Tested</p>
          </div>

          <div className="p-4 text-center justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-12 text-teal-600 m-auto"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 12a2.25 2.25 0 0 0-2.25-2.25H15a3 3 0 1 1-6 0H5.25A2.25 2.25 0 0 0 3 12m18 0v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 9m18 0V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v3"
              />
            </svg>

            <p className="text-gray-800 text-2xl pt-1">14</p>
            <p className="text-gray-800 text-sm pt-1">Award Winnings</p>
          </div>
        </div>

        <div className="lg:col-span-2 md:col-span-2 flex p-12 bg-gray-200 grid grid-cols-1 text-center justify-center items-center">
          <p className="text-gray-800 text-sm">
            “On extremely short notice, BlueOwlCreative came up with the perfect
            design I previously envisioned for my company.“
          </p>
          <p className="text-gray-800 text-md">ISSABELLE CORTOIX </p>
          <p className="text-gray-600 text-sm">Marketing Manager</p>
        </div>
      </div>

      {/* =====================Beautiful Design========================= */}

      <div className="p-24 text-center justify-center items-center bg-white">
        <div className="pt-12">
          <p className="text-gray-800 text-3xl">
            We create{" "}
            <strong className="text-teal-600">Beautiful Design</strong> and love
            doing it
          </p>
        </div>
        <div className="bg-gray-300 m-auto w-24 h-px mt-8"></div>
        <p className="text-gray-400 italic text-lg pt-8">
          We are a young team of professionals that take pride in their work and
          would love to help you
        </p>
        <img src={CleanDesignImg} alt="deskimg" className="mt-12 bg-cover" />
      </div>

      {/* =================about final=================== */}

      <div className="bg-bgmain p-12">
        <div className="grid lg:grid-cols-8 md:grid-cols-8">
          <div className="lg:col-span-6 md:col-span-6 pl-12">
            <p className="text-white text-xl">
              WE ARE AWESOME & COOL PLUS OUR SERVICES ROCK
            </p>
            <p className="text-white text-sm pt-4">
              We create beautiful, user-friendly, feature-rich WordPress
              Templates that both you and your clients will love.
            </p>
          </div>
          <div className="lg:col-span-2 md:col-span-2 pl-12 sm:pt-8">
            <button className="hover:bg-gray-200 hover:text-gray-800 m-auto">
              <span className="float-left">BUY NOW </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="size-6 right-arrow"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m8.25 4.5 7.5 7.5-7.5 7.5"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default aboout;
