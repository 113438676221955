import React from "react";
import "./home.css";

const Passionate = () => {
  return (
    <div className="creative lg:p-32 md:p-32 sm:p-12 p-12">
      <p className="creative-content">
        <span>More About </span>
        <span className="red-you">Code Knight</span>
        {"   "}
      </p>
      <div className="create-divider"></div>
      <p className="creative-content-letter md:w-96 lg:w-96">
        Code Knight is a premier software development team based in Los Angeles,
        CA, dedicated to delivering high-quality and high-performance solutions.
        With a team of expert-level developers, we specialize in Mobile, Web,
        and Chrome Extension development, catering to a diverse range of clients
        and industries.
      </p>

      <div className="boc-spacing"></div>
      <a href="../contact" className="btn get-started-btn">
        <span>LEARN MORE {">"}</span>
      </a>
    </div>
  );
};

export default Passionate;
