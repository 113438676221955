import React, { useState, useRef, useEffect } from "react";
import Net from "../assets/about-slides/net-150x100.png";
import Vue from "../assets/about-slides/vue-150x100.png";
import Ionic from "../assets/about-slides/ionic-150x100.png";
import Swift from "../assets/about-slides/swift-150x100.png";
import Jive from "../assets/about-slides/zive-150x100.png";
import Sales from "../assets/about-slides/sal-150x100.png";
import Angla from "../assets/about-slides/an-150x100.png";
import Laravel from "../assets/about-slides/la-150x100.png";
import Re from "../assets/about-slides/reac-150x100.png";

const data = [
  { title: "Net", img: Net },
  { title: "Vue", img: Vue },
  { title: "Ionic", img: Ionic },
  { title: "Swift", img: Swift },
  { title: "Jive", img: Jive },
  { title: "Sales", img: Sales },
  { title: "Angla", img: Angla },
  { title: "Laravel", img: Laravel },
  { title: "Re", img: Re },
];

const AboutCarousel = () => {
  const maxScrollWidth = useRef(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const carousel = useRef(null);

  const movePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  const moveNext = () => {
    if (
      carousel.current !== null &&
      carousel.current.offsetWidth * currentIndex <= maxScrollWidth.current
    ) {
      setCurrentIndex((prevState) => prevState + 1);
    }
  };

  const isDisabled = (direction) => {
    if (direction === "prev") {
      return currentIndex <= 0;
    }

    if (direction === "next" && carousel.current !== null) {
      return (
        carousel.current.offsetWidth * currentIndex >= maxScrollWidth.current
      );
    }

    return false;
  };

  useEffect(() => {
    if (carousel.current !== null) {
      carousel.current.scrollLeft = carousel.current.offsetWidth * currentIndex;
    }
  }, [currentIndex]);

  useEffect(() => {
    maxScrollWidth.current = carousel.current
      ? carousel.current.scrollWidth - carousel.current.offsetWidth
      : 0;
  }, []);

  return (
    <div className="px-24 py-12 mx-auto bg-gray-100">
      <div className="relative overflow-hidden bg-teal-500">
        <div className="flex justify-between absolute top left w-full h-full">
          <button
            onClick={movePrev}
            className="hover:bg-blue-900/75 text-white rounded-full h-10 w-10 mx-4 my-12 text-center opacity-75 hover:opacity-100 disabled:opacity-25 disabled:cursor-not-allowed z-10 p-0 m-0 transition-all ease-in-out duration-300"
            disabled={isDisabled("prev")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 ml-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 19l-7-7 7-7"
              />
            </svg>
            <span className="sr-only">Prev</span>
          </button>
          <button
            onClick={moveNext}
            className="hover:bg-blue-900/75 text-white rounded-full h-10 w-10 mx-4 my-12 text-center opacity-75 hover:opacity-100 disabled:opacity-25 disabled:cursor-not-allowed z-10 p-0 m-0 transition-all ease-in-out duration-300"
            disabled={isDisabled("next")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 ml-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 5l7 7-7 7"
              />
            </svg>
            <span className="sr-only">Next</span>
          </button>
        </div>
        <div
          ref={carousel}
          className="px-16 carousel-container relative flex gap-1 overflow-hidden scroll-smooth snap-x snap-mandatory touch-pan justify-start sm:flex-columns-1 md:flex-columns-5 lg:flex-columns-5"
        >
          {data.map((resource, index) => (
            <div
              key={index}
              className="carousel-item flex-none flex-shrink-0 px-4"
            >
              <img
                src={resource.img}
                alt={resource.title}
                className="h-32 w-32 object-contain"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutCarousel;
