import React from "react";
import CarouselSlider from "./slider";
import Face1 from "../../assets/face_1.jpg";
import Face2 from "../../assets/face_2.jpg";
import Face3 from "../../assets/face_3.jpg";

const slides = [
  {
    contents:
      "“Quickly maximize timely deliverables for real-time schemas. Dramatically maintain clicks-and-mortar solutions without functional solutions.”",
    title: "JENNA STONE",
    img: Face1,
    subtitle: "Designer",
  },
  {
    contents:
      "“Solid Performance and Amazing Support! Dramatically maintain clicks-and-mortar solutions without functional solutions.”",
    title: "SEBASTIAN BROWN",
    img: Face2,
    subtitle: "Developer",
  },
  {
    contents:
      "“Professionally cultivate one-to-one customer service with robust ideas. Dynamically innovate resource-leveling customer service for state of the art customer service.”",
    title: "LINDSAY FORD",
    img: Face3,
    subtitle: "Project Leader",
  },
];

const MyFields = () => {
  return (
    <div className="bg-white pb-12">
      <div className="myfields-content grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1">
        <div className="expertise p-12 lg:pt-24 lg:pl-24 md:pt-24 md:pl-24">
          <p className="text-2xl text-zinc-800">
            Our <span style={{ color: "#c72245" }}>EXPERTISE</span>
          </p>
          <div className="my-5 level_divider"></div>
          <div className="my-4">
            <span className="icon-expertise">
              <i className="fas fa-trophy"></i>
            </span>
            <p className="icon-text text-lg text-zinc-700">
              Mobile Development
            </p>
            <p className="text-sm text-zinc-600 my-6">
              Our mobile development team excels in creating user-friendly and
              high-performance applications for iOS and Android platforms. We
              focus on delivering seamless user experiences and robust
              functionality.
            </p>
          </div>
          <div className="my-4">
            <span className="icon-expertise">
              <i className="fas fa-laptop-code"></i>
            </span>
            <p className="icon-text text-lg text-zinc-700">Web Development</p>
            <p className="text-sm text-zinc-600 my-6">
              We design and develop responsive, dynamic, and visually appealing
              websites. Our web solutions are tailored to enhance user
              engagement and drive business growth.
            </p>
          </div>
          <div className="my-4">
            <span className="icon-expertise">
              <i className="fas fa-mobile-alt"></i>
            </span>
            <p className="icon-text text-lg text-zinc-700">
              Chrome Extension Development
            </p>
            <p className="text-sm text-zinc-600 my-6">
              Our Chrome extensions are built to enhance browser functionality,
              providing users with valuable features and improved performance.
              We ensure our extensions are efficient, easy to use, and reliable.
            </p>
          </div>
        </div>
        <div className="slider p-12 lg:pt-24 lg:pl-24 md:pt-24 md:pl-24">
          <p className="text-lg text-zinc-800">
            WHAT <span style={{ color: "#c72245" }}>OUR CLIENTS</span> SAY
          </p>
          <div className="my-5 level_divider"></div>
          <CarouselSlider slides={slides} />
        </div>
      </div>
    </div>
  );
};

export default MyFields;
