import React from "react";
import "./home.css";
import Majr from "../../assets/MAJR-600x600.png";
import Nfhoney from "../../assets/nfhoney-600x600.jpg";
import BabyGhost from "../../assets/babyghosts-600x600.jpg";
import Pastel from "../../assets/pastel-600x600.jpg";
import Measuredrisk from "../../assets/measuredrisk-600x600.jpg";
import YikeBike from "../../assets/yikebike-600x600.jpg";
import Ugru from "../../assets/ugru-600x600.jpg";
import Scalelab from "../../assets/scalelab-600x600.jpg";
import Noontime from "../../assets/noontime-600x600.jpg";
import { StarIcon } from "@heroicons/react/24/solid";

import ProgressBar from "./ProgressBar";

const Card = [
  Majr,
  Nfhoney,
  BabyGhost,
  Pastel,
  Measuredrisk,
  YikeBike,
  Ugru,
  Scalelab,
  Noontime,
];

const OurPortfolio = () => {
  return (
    <div className="our-portfolio bg-white justify-between items-center p-12">
      <div className="great-bookmark">
        <div className="bookmark w-5 h-5 absolute top-5">
          <StarIcon />
        </div>
      </div>
      <p className="great-company-title">
        LATEST FROM <strong className="great-red"> OUR PORTFOLIO</strong>
      </p>
      <p className="great-company-content">
        We are great at what we do and our Services simply rock
      </p>

      <div className="lg:columns-3 md:columns-2 sm:columns-1 xs:columns-1 portfolio-content">
        {Card.map((img, key) => (
          <div key={key} className="portfolio-card">
            <div className="img-cover">
              <img
                src={img}
                className="portfolio-img  max-w-full h-auto"
                alt={`Portfolio Items ${key + 1}`}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="skill-level lg:columns-2 md:columns-1 sm:columns-1">
        <div className="our-skill">
          <p className="level-title">
            OUR SKILL <strong className="level-red"> LEVELS</strong>
          </p>
          <div className="level_divider"></div>
          <div className="progressbar">
            <div className="progressbar-wrap">
              <p className="progress-title">Web Development</p>
              <ProgressBar bgcolor="crimson" progress="98" height={8} />
            </div>
            <div className="progressbar-wrap">
              <p className="progress-title">Mobile Applications</p>
              <ProgressBar bgcolor="crimson" progress="96" height={8} />
            </div>
            <div className="progressbar-wrap">
              <p className="progress-title">Chrome Extension</p>
              <ProgressBar bgcolor="crimson" progress="92" height={8} />
            </div>
          </div>
        </div>
        <div className="more-about-our-company">
          <p className="level-title ">
            Our <strong className="level-red">Process</strong>
          </p>
          <div className="level_divider"></div>
          <div className="more-about-content">
            <p>
              <strong className="level-red">Discovery and Planning: </strong> We
              start by understanding your needs and goals. Through detailed
              discussions and analysis, we develop a comprehensive plan that
              outlines the project scope, timeline, and deliverables.
              <br />
              <strong className="level-red">Design and Development:</strong> Our
              design team creates intuitive and visually appealing interfaces,
              while our developers bring these designs to life using the latest
              technologies and best practices. We ensure that every aspect of
              the solution is optimized for performance and usability.
              <br />
              <strong className="level-red">
                Testing and Quality Assurance
              </strong>{" "}
              We conduct thorough testing to identify and fix any issues. Our
              quality assurance process ensures that the final product is
              reliable, efficient, and ready for deployment.
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OurPortfolio;
