import React, { useState } from "react";
import "./navbar.css";
import logo from "../../assets/logo.png";
import { Bars3BottomRightIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  let Links = [
    { name: "HOME", link: "/" },
    { name: "ABOUT", link: "/about" },
    { name: "PORTFOLIO", link: "/portfolio" },
    { name: "BLOG", link: "/blog" },
    { name: "CONTACT", link: "/contact" },
  ];
  const [isOpen, setIsOpen] = useState(false);
  const [fix, setFix] = useState(false);

  function setFixed() {
    if (window.scrollY >= 200) {
      setFix(true);
    } else {
      setFix(false);
    }
  }
  window.addEventListener("scroll", setFixed);
  return (
    <div className={fix ? "w-full header" : "w-full fixed-header"}>
      <div className="lg:px-10 lg:flex justify-between items-center">
        <div className="flex text-2xl cursor-pointer logo-img">
          <img src={logo} alt="logo" className="logo" />
        </div>
        <div
          className="w-7 h-7 absolute right-8 top-6 cursor-pointer lg:hidden"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {isOpen ? <XMarkIcon /> : <Bars3BottomRightIcon />}
        </div>
        <ul
          className={`nav-ul lg:flex lg:pl-0 ${
            isOpen ? "toggle-open" : "toggle-close"
          } `}
        >
          {Links.map((link) => (
            <li className="nav-li lg:my-5" key={link.name}>
              <NavLink
                to={link.link}
                className={({ isActive }) =>
                  isActive ? "active nav-a" : "nav-a"
                }
              >
                {link.name}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
