import { Route, Routes } from "react-router-dom";
import Navbar from "./components/NavBar/navbar";
import Home from "./components/Home/home";
import About from "./components/About/about";
import Portfolio from "./components/Portfolio/portfolio";
import SinglePortfolio from "./components/Portfolio/singlePortfolio";
import Blog from "./components/Blog/blog";
import Contact from "./components/Contact/contact";
import Error from "./components/Error";
import Footer from "./components/Footer/footer";
import "./App.css";
function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/portfolio/:title" element={<SinglePortfolio />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
