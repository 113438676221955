import React, { useState } from "react";
import DocumentTitle from "../DocumentTitle";

const Contact = () => {
  DocumentTitle("Contact");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform form submission logic here
    console.log(formData);
    // Reset the form
    setFormData({
      name: "",
      email: "",
      subject: "",
      message: "",
    });
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <section>
      <div className="contact px-12 pt-36 pb-24 lg:pt-56 lg:pb-24 md:pt-36 md:pb-24 sm:pt-36">
        <div className="text-center">
          <p className="text-5xl text-white pt-8">
            CONTACT <strong className="text-bgmain">US</strong>{" "}
          </p>
          <div className="great-bookmark "></div>
          <p className="mt-12 text-xl text-gray-300 italic font-doris font-medium">
            Feel Free Contact US at any time
          </p>
        </div>
      </div>

      <div className="p-16 text-gray-600 bg-zinc-200">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 p-4">
          <div className="lg:col-span-2 md:col-span-1 col-span-1 bg-white p-8">
            <form onSubmit={handleSubmit}>
              <label className="text-lg" htmlFor="">
                CONTACT <span className="text-bgmain">FORM</span>
              </label>
              <div className="bg-gray-200 h-px w-24 mt-4"></div>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Your Name *"
                className="contact-input mt-12 w-full px-4 py-2 border border-b border-teal-600 p-8 inline-block"
                required
              />

              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Your Email *"
                className="contact-input mt-12 w-full px-4 py-2 border border-b border-teal-600 p-8 inline-block"
                required
              />
              <input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                placeholder="Subject *"
                className="contact-input mt-12 w-full px-4 py-2 border border-b border-teal-600 p-8 inline-block"
                required
              />
              <textarea
                name="message"
                id="message"
                cols="30"
                rows="5"
                value={formData.message}
                onChange={handleChange}
                placeholder="Message *"
                className="contact-input mt-12 w-full px-4 py-2 border border-b border-teal-600 p-8 inline-block"
              ></textarea>
              <button
                type="submit"
                className="bg-bgmain mt-8 text-white font-medium py-2 px-4 rounded-md hover:bg-blue-600"
              >
                Submit
              </button>
            </form>
          </div>
          <div className="col-span-1 pl-8">
            <p className="text-lg">
              OUR <span className="text-bgmain">LOCATION</span>
            </p>
            <div className="mt-8">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12805.959328687175!2d-118.22726554928211!3d34.02485686251214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c8b04ae2e5c5%3A0x2c365a8fba5676b!2s2929%20E%20Olympic%20Blvd%20%23121%2C%20Los%20Angeles%2C%20CA%2090023%2C%20USA!5e0!3m2!1sen!2sla!4v1606497550306!5m2!1sen!2sla"
                style={{ border: 0, width: 350, height: 350 }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
                title="contact map"
              />
            </div>
            <p className="text-lg pt-8">
              CONTACT <span className="text-bgmain">DETAILS</span>
            </p>
            <div>
              <p className="mt-8 text-gray-600 flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="size-4 text-bgmain"
                >
                  <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                  <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
                </svg>
                <p className="pl-4 -mt-1">info@code-knight.com</p>
              </p>
              <p className="mt-4 text-gray-600 flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="size-4 text-bgmain"
                >
                  <path
                    fillRule="evenodd"
                    d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                    clipRule="evenodd"
                  />
                </svg>

                <p className="pl-4 -mt-1">
                  2929 East Olympic Drive, Apt 121, Los Angeles, CA 90023
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
