import React from "react";
import { useParams } from "react-router-dom";
import DocumentTitle from "../DocumentTitle";
import Portfolioarray from "./portfolio.json";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SinglePortfolio = () => {
  const { title } = useParams();
  DocumentTitle(title + ` - Code Knight`);
  const portfolioarrary = Portfolioarray;
  return (
    <section>
      <div className="single-portfolio-bg">
        <p className="text-5xl text-white text-gray-600  pt-40 pb-20">
          {title}
        </p>
      </div>

      {/* =============Single Portfolio================== */}
      {/* <div className="bg-gray-100 p-8 lg:px-32">
       
      </div> */}
      {portfolioarrary.map((portCard, index) => {
        if (portCard.title === title) {
          const skeyItems = portCard.skey.slice(1);
          return (
            <div className="bg-white grid grid-cols-3 p-8 lg:px-28" key={index}>
              <div className="flex col-span-2 text-gray-800 p-8">
                <LazyLoadImage
                  src={require(`../../assets/${portCard.portimg}`)}
                  alt={portCard.title}
                  className="transition duration-300 ease-in-out transform"
                />
              </div>
              <div className=" col-span-1 text-gray-800 p-8">
                <p className="text-lg pb-4">
                  PROJECT <strong className="text-bgmain">DESCRIPTION</strong>{" "}
                </p>
                <p>{portCard.description}</p>
                <div className="pt-8">
                  <p className="text-lg pt-4">
                    WHAT <strong className="text-bgmain">OUR TEAM</strong>{" "}
                    PROVIDED
                  </p>
                  <div className="pt-4">
                    {skeyItems.map((skills, index) => {
                      return (
                        <div key={index} className="flex pt-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-6 text-bgmain"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 12.75 11.25 15 15 9.75m-3-7.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285Z"
                            />
                          </svg>

                          <p className="pl-4">{skills}</p>
                        </div>
                      );
                    })}
                  </div>
                  <div className="mt-6">
                    <a
                      className="mt-4 px-6 py-3 bg-bgmain border rounded-md border-inherit text-white"
                      href={portCard.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Visit Site
                    </a>
                  </div>
                </div>
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}
    </section>
  );
};

export default SinglePortfolio;
